import { default as indexYP1JgmvDB1Meta } from "/src/pages/admin/announcements/index.vue?macro=true";
import { default as newKEFz4ucx1EMeta } from "/src/pages/admin/announcements/new.vue?macro=true";
import { default as inbox4vrzrVkgemMeta } from "/src/pages/admin/inbox.vue?macro=true";
import { default as indexwK3fSZ6B4uMeta } from "/src/pages/admin/index.vue?macro=true";
import { default as indexiCvbqdE80yMeta } from "/src/pages/admin/propositions/index.vue?macro=true";
import { default as indexCgKkNvHysvMeta } from "/src/pages/admin/settings/index.vue?macro=true";
import { default as membersoNRdyxN4lmMeta } from "/src/pages/admin/settings/members.vue?macro=true";
import { default as notificationsVOPGr4buuxMeta } from "/src/pages/admin/settings/notifications.vue?macro=true";
import { default as settingsPnaYdGxtTRMeta } from "/src/pages/admin/settings.vue?macro=true";
import { default as users6OoYEhHx7ZMeta } from "/src/pages/admin/users.vue?macro=true";
import { default as _91slug_93fKci1AYo0mMeta } from "/src/pages/blog/[slug].vue?macro=true";
import { default as index380d8Ul5jBMeta } from "/src/pages/blog/index.vue?macro=true";
import { default as bloglxVOpFDfjAMeta } from "/src/pages/blog.vue?macro=true";
import { default as _91_46_46_46slug_93SPMWj4ZEEjMeta } from "/src/pages/docs/[...slug].vue?macro=true";
import { default as docse3h95GVbTSMeta } from "/src/pages/docs.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as loginTLBFe6DICLMeta } from "/src/pages/login.vue?macro=true";
import { default as pricingYsO4phc8ASMeta } from "/src/pages/pricing.vue?macro=true";
import { default as indexGipUkEBhgwMeta } from "/src/pages/projects/[project_unique_name]/index.vue?macro=true";
import { default as signupKewoTZgniIMeta } from "/src/pages/signup.vue?macro=true";
export default [
  {
    name: "admin-announcements",
    path: "/admin/announcements",
    meta: indexYP1JgmvDB1Meta || {},
    component: () => import("/src/pages/admin/announcements/index.vue")
  },
  {
    name: "admin-announcements-new",
    path: "/admin/announcements/new",
    meta: newKEFz4ucx1EMeta || {},
    component: () => import("/src/pages/admin/announcements/new.vue")
  },
  {
    name: "admin-inbox",
    path: "/admin/inbox",
    meta: inbox4vrzrVkgemMeta || {},
    component: () => import("/src/pages/admin/inbox.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexwK3fSZ6B4uMeta || {},
    component: () => import("/src/pages/admin/index.vue")
  },
  {
    name: "admin-propositions",
    path: "/admin/propositions",
    meta: indexiCvbqdE80yMeta || {},
    component: () => import("/src/pages/admin/propositions/index.vue")
  },
  {
    name: settingsPnaYdGxtTRMeta?.name,
    path: "/admin/settings",
    meta: settingsPnaYdGxtTRMeta || {},
    component: () => import("/src/pages/admin/settings.vue"),
    children: [
  {
    name: "admin-settings",
    path: "",
    component: () => import("/src/pages/admin/settings/index.vue")
  },
  {
    name: "admin-settings-members",
    path: "members",
    component: () => import("/src/pages/admin/settings/members.vue")
  },
  {
    name: "admin-settings-notifications",
    path: "notifications",
    component: () => import("/src/pages/admin/settings/notifications.vue")
  }
]
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/src/pages/admin/users.vue")
  },
  {
    name: bloglxVOpFDfjAMeta?.name,
    path: "/blog",
    component: () => import("/src/pages/blog.vue"),
    children: [
  {
    name: "blog-slug",
    path: ":slug()",
    component: () => import("/src/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "",
    component: () => import("/src/pages/blog/index.vue")
  }
]
  },
  {
    name: "docs",
    path: "/docs",
    component: () => import("/src/pages/docs.vue"),
    children: [
  {
    name: "docs-slug",
    path: ":slug(.*)*",
    component: () => import("/src/pages/docs/[...slug].vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginTLBFe6DICLMeta || {},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/src/pages/pricing.vue")
  },
  {
    name: "projects-project_unique_name",
    path: "/projects/:project_unique_name()",
    meta: indexGipUkEBhgwMeta || {},
    component: () => import("/src/pages/projects/[project_unique_name]/index.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupKewoTZgniIMeta || {},
    component: () => import("/src/pages/signup.vue")
  }
]