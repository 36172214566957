import axios from 'axios';
import { defineStore } from 'pinia';
import type { SimpleUserFromApi, SimpleUserWithTokenFromApi } from '~/types/interfaces';

export interface UserState {
    uid: string,
    email: string,
    accessToken: string,
    is_registered_client: boolean,
    first_name: string,
    last_name: string,
    avatar_details: {
        url: string | undefined,
        uid: string | undefined,
    },
    user_complete?: SimpleUserFromApi,
}

export const useAuthStore = defineStore('auth', () => {
    let promiseFetchAccessTokenFromRefreshToken: Promise<string>;
    let promiseFetchMyUser: Promise<SimpleUserFromApi>;
    // Based on https://github.com/vuejs/pinia/issues/58#issuecomment-983284955
    // This part is important because we want to avoid mutation of the state OUTSIDE the store !!
    // And https://pinia.vuejs.org/core-concepts/#Setup-Stores
    const state = reactive<UserState>({
        uid: '',
        email: '',
        accessToken: '',
        is_registered_client: true,
        first_name: '',
        last_name: '',
        avatar_details: {
            uid: undefined,
            url: undefined,
        },
        user_complete: undefined,
        // organisation: undefined,
    });

    const isLoggedIn = computed<boolean>(() => !!(state.email && state.uid));
    const isLoggedInAsClient = computed<boolean>(() => isLoggedIn.value && !!state.user_complete?.organisation);
    // const isLoggedInAsPublic = computed<boolean>(() => isLoggedIn.value && !state.is_registered_client);
    // const isLoggedInAsFreeAccess = computed<boolean>(() => isLoggedIn.value && !state.organisation?.is_active_payer);
    // const isLoggedInAsAssociation = computed<boolean>(() => isLoggedIn.value && state.association_selected !== null);
    // const associations = computed(() => Object.values(state.associationsByUid));
    // const accountSelected = computed(() => {
    //     return (isLoggedInAsAssociation.value)
    //         ? {
    //                 label: state.association_selected?.name,
    //                 avatar_url: state.association_selected?.logo_details?.url,
    //             }
    //         : {
    //                 label: state.email,
    //                 avatar_url: state.avatar_details.url,
    //             };
    // });
    // const isFreeAccessWithoutBanner = computed<boolean>(() => isLoggedInAsFreeAccess.value && state.organisation?.banner === null);
    //
    function setUserData(data: SimpleUserFromApi | SimpleUserWithTokenFromApi) {
        state.uid = data.uid;
        state.email = data.email;
        state.first_name = data.first_name;
        state.last_name = data.last_name;
        state.user_complete = data;
    }
    async function signup({ email, password, first_name, last_name }: { email: string, password: string, first_name: string, last_name: string }) {
        const response = await axios.post<SimpleUserWithTokenFromApi>('auth/signup/', {
            email,
            password,
            first_name,
            last_name,
        });
        if (response.access) {
            state.accessToken = response.access;
            setUserData(response);
        }
    }
    //
    async function login({ email, password }: { email: string, password: string }) {
        const response = await axios.post<SimpleUserWithTokenFromApi>('auth/login/', {
            email,
            password,
        });
        if (response.access) {
            state.accessToken = response.access;
            setUserData(response);
        }
    }
    //
    async function fetchAccessTokenFromRefreshToken(): Promise<string> {
        if (promiseFetchAccessTokenFromRefreshToken) {
            return promiseFetchAccessTokenFromRefreshToken;
        }
        promiseFetchAccessTokenFromRefreshToken = axios.post<{ access: string }>('auth/token/refresh/').then((response) => {
            state.accessToken = response.access;
            return response.access;
        }).catch(() => {
            return '';
        }).finally(() => {
            promiseFetchAccessTokenFromRefreshToken = undefined;
        });
        return promiseFetchAccessTokenFromRefreshToken;
    }
    //
    // async function setOrganisationProperty<K extends keyof OrganisationFromApi>(key: K, value: OrganisationFromApi[K]) {
    //     if (state.organisation) {
    //         state.organisation[key] = value;
    //     }
    // }
    //
    // async function fetchMyOrganisation() {
    //     state.organisation = await axios.get<OrganisationFromApi>('my_organisation/');
    // }
    //
    async function fetchMyUser(forceReload = false): Promise<SimpleUserFromApi> {
        if (!forceReload && state.user_complete?.uid) {
            return state.user_complete;
        }
        if (!promiseFetchMyUser || forceReload) {
            promiseFetchMyUser = axios.get<SimpleUserFromApi>('my_user/').then((response) => {
                setUserData(response);
                // state.is_registered_client = response.is_registered_client;
                // state.avatar_details = {
                //     uid: response.avatar_details?.uid ?? undefined,
                //     url: response.avatar_details?.url ?? undefined,
                // };
                // state.user_complete.avatar_details = {
                //     uid: response.avatar_details?.uid ?? undefined,
                //     url: response.avatar_details?.url ?? undefined,
                // };

                // if (response.is_registered_client) {
                //     fetchMyOrganisation();
                // }
                return response;
            });
        }
        return promiseFetchMyUser;
    }
    //
    // async function updateAvatar(avatar: UploadableFile) {
    //     avatar.type = UploadedFileType.AVATAR;
    //     const uidKey = state.association_selected ? 'user_association_uid' : '';
    //     const uid = state.association_selected ? state.association_selected.uid : '';
    //     const response = await avatar.uploadFile(uidKey, uid);
    //     if (response) {
    //         if (state.association_selected) {
    //             state.associationsByUid[state.association_selected?.uid].logo_details = {
    //                 url: response.signed_url,
    //                 uid: response.uid,
    //             };
    //             state.association_selected = state.associationsByUid[state.association_selected?.uid];
    //         } else {
    //             state.avatar_details.url = response.signed_url;
    //             state.avatar_details.uid = response.uid;
    //             state.user_complete.avatar_details.url = response.signed_url;
    //             state.user_complete.avatar_details.uid = response.uid;
    //         }
    //     }
    // }
    //
    // async function updateBanner(banner: UploadedFileFromApi) {
    //     if (state.organisation) {
    //         state.organisation.banner = banner;
    //     }
    // }
    //
    // async function checkIfEmailExists(email: string): Promise<boolean> {
    //     const { exists } = await axios.post<{ exists: boolean }>('check_if_email_exists/', { email });
    //     return exists;
    // }
    //
    // async function deleteAvatar() {
    //     if (state.association_selected) {
    //         if (state.association_selected?.logo_details?.uid) {
    //             await deleteFile(state.association_selected?.logo_details?.uid);
    //             state.associationsByUid[state.association_selected?.uid].logo_details = {
    //                 url: undefined,
    //                 uid: undefined,
    //             };
    //             state.association_selected = state.associationsByUid[state.association_selected?.uid];
    //         }
    //         return;
    //     }
    //
    //     if (state.avatar_details.uid) {
    //         await deleteFile(state.avatar_details.uid);
    //         state.avatar_details = {
    //             uid: undefined,
    //             url: undefined,
    //         };
    //         state.user_complete.avatar_details = {
    //             uid: '',
    //             url: '',
    //         };
    //     }
    // }
    //
    function $reset() {
        state.uid = '';
        state.email = '';
        state.accessToken = '';
        state.is_registered_client = false;
        state.first_name = '';
        state.last_name = '';
        state.avatar_details = {
            uid: undefined,
            url: undefined,
        };
        state.user_complete = undefined;
    }
    async function logout(navigateToLogin = true) {
        $reset();
        await axios.post<{ access: string }>('auth/token/blacklist/');
        if (navigateToLogin) {
            window.location.href = '/';
        }
    }
    // // async updateReservation(reservation: Partial<ReservationFromApi> & {uid:string}): Promise<ReservationFromApi> {
    // //         const response = await axios.patch<ReservationFromApi>(`reservations/${reservation.uid}/`, reservation);
    // //         this.reservationsByUid[response.uid] = this.transformReservationFromApiToStore(response);
    // //         return response;
    // //     },
    // async function updateMyUser(userToBeUpdated: Partial<UserWithProfileFromApi>): Promise<UserWithProfileFromApi> {
    //     const response = await axios.patch<UserWithProfileFromApi>('my_user/', userToBeUpdated);
    //     state.user_complete = response;
    //     state.first_name = response.first_name;
    //     state.last_name = response.last_name;
    //     return response;
    // }
    //
    // function switchForAssociation(user_association_uid: string | null) {
    //     if (!user_association_uid) {
    //         state.association_selected = null;
    //         localStorage.removeItem('asso_selected');
    //         return;
    //     }
    //     const asso = associations.value.find(a => a.uid === user_association_uid);
    //     if (asso) {
    //         state.association_selected = state.associationsByUid[user_association_uid];
    //         localStorage.setItem('asso_selected', user_association_uid);
    //     }
    // }
    //
    // async function createAssociation(data: Partial<UserAssociationFromApi>): Promise<UserAssociationFromApi> {
    //     const newAssociation = await axios.post<UserAssociationFromApi>('user_associations/', data);
    //     state.associationsByUid[newAssociation.uid] = newAssociation;
    //     return newAssociation;
    // }
    //
    // function setAssociations(localAssociations: UserAssociationFromApi[]) {
    //     const temp: { [uid: string]: UserAssociationFromApi } = {};
    //     localAssociations.forEach((association) => {
    //         temp[association.uid] = association;
    //     });
    //     state.associationsByUid = temp;
    // }
    //
    // async function updateAssociation(associationToBeUpdated: Partial<UserAssociationFromApi> & { uid: string }) {
    //     state.associationsByUid[associationToBeUpdated.uid] = await axios.patch<UserAssociationFromApi>(`user_associations/${associationToBeUpdated.uid}/`, associationToBeUpdated);
    //     if (state.association_selected) {
    //         state.association_selected = state.associationsByUid[associationToBeUpdated.uid];
    //     }
    // }
    //
    // async function deleteAssociation(uid: string) {
    //     await axios.delete(`user_associations/${uid}/`);
    //     delete state.associationsByUid[uid];
    // }

    return {
        ...toRefs(readonly(state)),
        isLoggedIn,
        isLoggedInAsClient,
        // isLoggedInAsPublic,
        // isLoggedInAsFreeAccess,
        // isLoggedInAsAssociation,
        // accountSelected,
        // associations,
        // isFreeAccessWithoutBanner,
        signup,
        login,
        fetchAccessTokenFromRefreshToken,
        fetchMyUser,
        // updateAvatar,
        // updateBanner,
        logout,
        // checkIfEmailExists,
        // updateMyUser,
        // deleteAvatar,
        // setOrganisationProperty,
        // switchForAssociation,
        // createAssociation,
        // updateAssociation,
        // deleteAssociation,
    };
});
